import ServiceToken from '@apps/subscriptions/admin/tokens/service'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Billing Subscriptions)',
  collection: {
    endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/billing_subscriptions`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Service)', key: 'service.title', primary: true, format: (subscription) => (
        <ServiceToken service={ subscription.service } />
      ) },
      { label: 't(Plan)', key: 'current_period.plan.title', collapsing: true, sort: 'plan' }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'refresh',
      title: 't(No Billing Subscriptions)',
      text: 't(You have not yet configured any billing subscriptions for this advsior)',
      buttons: [
        { 
          label: 't(Create Billing Subscriptions)',
          modal: <New advisor={ props.advisor } />
        }
      ]
    },
    entity: 'billing subscription',
    onClick: (record) => context.router.push(`/subscriptions/services/${record.service.id}/subscriptions/${record.id}`)
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Billing Subscriptions)',
    modal: <New advisor={ props.advisor } />
  }
})

export default Panel(null, mapPropsToPanel)
