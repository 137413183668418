import SocialPublishings from './social_publishings'
import EmailPublishings from './email_publishings'
import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import EmailTemplates from './email_templates'
import EmailArticles from './email_articles'
import SocialBatches from './social_batches'
import AdvisorUsers from './standard_users'
import EmailModules from './email_modules'
import SupportUsers from './support_users'
import Page from '@admin/components/page'
import Advisors from './advisors'
import Channels from './channels'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Truevail)',
  manager: {
    path: '/admin/truevail/agency',
    items: [
      {
        label: 't(Advisors)',
        path: '/advisors',
        panel: <Advisors />
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Articles)',
            path: '/email_articles',
            panel: <EmailArticles />
          },
          {
            label: 't(Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns />
          },
          {
            label: 't(Channels)',
            path: '/email_channels',
            panel: <Channels service="email" />
          },
          {
            label: 't(Modules)',
            path: '/email_modules',
            panel: <EmailModules medium="email" />
          },
          {
            label: 't(Publishings)',
            path: '/email_publishings',
            panel: <EmailPublishings />
          },
          {
            label: 't(Templates)',
            path: '/email_templates',
            panel: <EmailTemplates />
          }
        ]
      },
      {
        label: 't(Social Media)',
        children: [
          {
            label: 't(Batches)',
            path: '/social_batches',
            panel: <SocialBatches />
          },
          {
            label: 't(Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns />
          },
          {
            label: 't(Channels)',
            path: '/social_channels',
            panel: <Channels service="social" />
          },
          {
            label: 't(Publishings)',
            path: '/social_publishings',
            panel: <SocialPublishings />
          }
        ]
      },
      // {
      //   label: 't(Websites)',
      //   children: [
      //     {
      //       label: 't(Blog Posts)',
      //       path: '/posts',
      //       panel: <Foo />
      //     },
      //     {
      //       label: 't(Modules)',
      //       path: '/modules',
      //       panel: <Modules medium="page" />
      //     },
      //     {
      //       label: 't(Templates)',
      //       path: '/email_templates',
      //       panel: <Foo />
      //     }
      //   ]
      // },
      {
        label: 't(Users)',
        children: [
          {
            label: 't(Advisor Users)',
            path: '/standard_users',
            panel: <AdvisorUsers />
          },
          {
            label: 't(Support Users)',
            path: '/support_users',
            panel: <SupportUsers />
          }
        ]
      }
    ]
  }
})

export default Page(null, mapPropsToPage)
