import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Complete from './complete'
import Overview from './overview'
import React from 'react'

class Configure extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.any,
    completed: PropTypes.any,
    endpoint: PropTypes.string,
    intro: PropTypes.any,
    mode: PropTypes.string,
    requirements: PropTypes.array,
    setup: PropTypes.object,
    title: PropTypes.string
  }

  stackRef = React.createRef()

  state = {
    configuration: null,
    status: null
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleComplete = this._handleComplete.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleTask = this._handleTask.bind(this)

  render() {
    if(this.state.configuration === null) return null
    return <Stack { ...this._getStack() } />
  }

  componentDidMount() {
    const { setup } = this.props
    const { configuration, status } = setup
    this.setState({ 
      configuration,
      status: status === 'configured' ? 'complete' : 'overview' 
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { configuration, status } = this.state
    if(!_.isEqual(configuration, prevState.configuration) && prevState.configuration !== null) {
      this._handleSave()
    }
    if(status !== prevState.status && status === 'overview') {
      this._handleOverview()
    }
    if(status !== prevState.status && status === 'complete') {
      this._handleCompleted()
    }
  }

  _getCompleted() {
    const { completed, title } = this.props
    return { 
      completed,
      title
    }
  }

  _getOverview() {
    const { intro, mode, requirements, title } = this.props
    const { configuration } = this.state
    return {
      configuration,
      intro,
      mode,
      requirements,
      title,
      onCancel: this._handleCancel,
      onDone: this._handleComplete,
      onSave: this._handleSave,
      onTask: this._handleTask
    }
  }

  _getStack() {
    return {
      display_name: 'configure',
      ref: this.stackRef
    }
  }

  _getTask(task) {
    const { configuration } = this.state
    const { advisor } = this.props
    return {
      advisor,
      configuration,
      onDone: this._handleDone.bind(this, task),
      onNext: this._handleNext.bind(this, task),
      onBack: this._handlePop
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleComplete() {
    const { endpoint, mode } = this.props
    if(mode !== 'initial') {
      return this.setState({
        status: 'complete'
      })
    }
    this.context.network.request({
      endpoint: `${endpoint}/complete`,
      method: 'PATCH',
      onSuccess: () => {
        this.setState({
          status: 'complete'
        })      
      },
      onFailure: (e) => this.context.flash.set('error', 't(Unable to complete configuration)')
    })
  }

  _handleCompleted() {
    this._handlePush(Complete, this._getCompleted.bind(this))
  }

  _handleDone(task, configuration) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        ...configuration,
        [`${task.code}_status`]: 'completed'
      }
    })
    this._handlePop()
  }

  _handleNext(task, configuration) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        ...configuration,
        [`${task.code}_status`]: _.isEqual(this.state.configuration, configuration) ? 'completed' : null
      }
    })
  }

  _handleOverview() {
    this._handlePush(Overview, this._getOverview.bind(this))
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleSave() {
    const { configuration } = this.state
    const { endpoint } = this.props
    this.context.network.request({
      endpoint,
      method: 'PATCH',
      body: { configuration },
      onFailure: (e) => this.context.flash.set('error', 't(Unable to save configuration)')
    })
  }

  _handleTask(task) {
    this._handlePush(task.modal, this._getTask.bind(this, task))
  }

}

const mapResources = (props) => ({
  setup: `${props.endpoint}/edit`
})

export default Container(mapResources)(Configure)
