import EntityChooser from '@admin/components/entity_chooser'
import ContentToken from '@apps/maha/admin/tokens/content'
import PropTypes from 'prop-types'
import React from 'react'

class Content extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { type } = this.props.props
    return {
      endpoint: `/api/admin/truevail/agency/content/${type}s/truevail`,
      entity: type,
      multiple: true,
      format: (content) => (
        <ContentToken content={ content } />
      ),
      preview: (entity) => `maha_contents/${entity.code}`,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(contents) {
    this.props.onNext({ contents })
  }

}

export default Content
