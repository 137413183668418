import BillingSubscriptions from './billing_subscriptions'
import EmailCampaigns from './email_campaigns'
import Subscriptions from './subscriptions'
import Configuration from './configuration'
import SupportUsers from './support_users'
import Page from '@admin/components/page'
import Templates from './templates'
import Overview from './overview'
import Profiles from './profiles'
import Senders from './senders'
import Signup from './signup'
import Emails from './emails'
import Deals from './deals'
import Forms from './forms'
import Lists from './lists'
import Users from './users'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.advisor.team.title,
  manager: {
    path: `/admin/truevail/admin/advisors/${resources.advisor.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview advisor={ resources.advisor } />
      },
      {
        label: 't(Billing Subscriptions)',
        path: '/billing_subscriptions',
        show: resources.advisor.team.is_active,
        panel: <BillingSubscriptions advisor={ resources.advisor } />
      },
      {
        label: 't(Configuration)',
        path: '/configuration',
        show: resources.advisor.team.is_active,
        panel: <Configuration advisor={ resources.advisor } />
      },
      {
        label: 't(Communication)',
        children: [
          {
            label: 't(Email)',
            path: '/email',
            panel: <Emails contact={ resources.advisor.team.contact } emailable="true" />
          }
        ]
      },
      {
        label: 't(Deals)',
        path: '/deals',
        show: resources.advisor.team.is_active,
        panel: <Deals contact={ resources.advisor.team.contact } />
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Campaigns)',
            path: '/email_campaigns',
            show: resources.advisor.team.is_active,
            panel: <EmailCampaigns advisor={ resources.advisor } />
          },
          {
            label: 't(Senders)',
            path: '/senders',
            show: resources.advisor.team.is_active,
            panel: <Senders advisor={ resources.advisor } />
          },
          {
            label: 't(Templates)',
            path: '/templates',
            show: resources.advisor.team.is_active,
            panel: <Templates advisor={ resources.advisor } />
          }
        ]
      },
      {
        label: 't(Forms)',
        path: '/forms',
        show: resources.advisor.team.is_active,
        panel: <Forms advisor={ resources.advisor } />
      },
      {
        label: 't(Lists)',
        path: '/lists',
        show: resources.advisor.team.is_active,
        panel: <Lists advisor={ resources.advisor } />
      },
      {
        label: 't(Social)',
        children: [
          {
            label: 't(Profiles)',
            path: '/profiles',
            show: resources.advisor.team.is_active,
            panel: <Profiles advisor={ resources.advisor } />
          }
        ]
      },
      {
        label: 't(Signup)',
        path: '/signup',
        show: resources.advisor.team.is_active && resources.advisor.signup !== null,
        panel: <Signup advisor={ resources.advisor } />
      },
      {
        label: 't(Subscriptions)',
        path: '/subscriptions',
        show: resources.advisor.team.is_active,
        panel: <Subscriptions advisor={ resources.advisor } />
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        show: resources.advisor.team.is_active,
        panel: <SupportUsers advisor={ resources.advisor } />
      },
      {
        label: 't(Users)',
        path: '/users',
        show: resources.advisor.team.is_active,
        panel: <Users advisor={ resources.advisor } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  advisor: `/api/admin/truevail/admin/advisors/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
