import CardToken from '@apps/finance/admin/tokens/card'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import numeral from 'numeral'
import React from 'react'

const Overview = ({ advisor, signup }) => {

  const details = {
    comments: `maha_signups/${signup.id}`,
    sections: [
      {
        title: 't(Signup Details)',
        items: [
          { label: 't(Signup Page)', content: signup.signup_page ? signup.signup_page.title : null },
          { label: 't(Signed Up At)', content: signup.created_at, format: 'datetime' },
          ...signup.configured_at ? [
            { label: 't(Configured At)', content: signup.configured_at, format: 'datetime' }
          ] : []
        ]
      },
      {
        title: 't(Signup Data)',
        items: [
          { label: 't(First Name)', content: signup.data.first_name },
          { label: 't(Last Name)', content: signup.data.last_name },
          { label: 't(Business Name)', content: signup.data.organization },
          { label: 't(Email)', content: signup.data.email },
          { label: 't(Referred By)', content: signup.data.referred_by },
          { label: 't(Agreed to Terms)', content: signup.data.agreed_to_terms ? 'Yes' : 'No' },
          { label: 't(Setup Fee)', content: numeral(signup.data.setup_fee).format('0.00') },
          { label: 't(Monthly Price)', content: numeral(signup.data.monthly_price).format('0.00') },
          { label: 't(Free Setup)', content: signup.data.free_setup ? 'Yes' : 'No' },
          { label: 't(Trial Length)', content: pluralize('month', signup.data.trial_length, true) },
          { label: 't(Payment Method)', padded: false, content: signup.payment_method ? <CardToken payment_method={ signup.payment_method } /> : null }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  advisor: PropTypes.object,
  signup: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview advisor={ props.advisor } signup={ props.signup } />
})

const mapResourcesToPanel = (props, context) => ({
  signup: `/api/admin/truevail/admin/advisors/${props.advisor.id}/signup`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)