import Workflows from './automation/workflows'
import Page from '@admin/components/page'
import Emails from './automation/emails'
import Overview from './overview'
import Design from './design'
import React from 'react'



const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.signup_page.title,
  manager: {
    path: `/admin/truevail/admin/signup_pages/${resources.signup_page.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview signup_page={ resources.signup_page } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design signup_page={ resources.signup_page } />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows signup_page={ resources.signup_page } />
          },
          {
            label: 't(Emails)',
            path: '/emails',
            panel: <Emails signup_page={ resources.signup_page } />
          }
        ]
      },
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  signup_page: `/api/admin/truevail/admin/signup_pages/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
