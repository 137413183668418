import Message from '@admin/components/message'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import Configure from './index'
import React from 'react'

class Initial extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  _handleSignout = this._handleSignout.bind(this)

  render() {
    return <Configure { ...this._getConfigure() } />
  }

  _getConfigure() {
    const { advisor, team, user } = this.context.admin
    return {
      title: 't(Account Setup)',
      advisor: {
        ...advisor,
        team
      },
      endpoint: '/api/admin/setup',
      mode: 'initial',
      requirements: ['identity','logo','brand','personalization','email','social','website','additional'],
      intro: (
        <>
          <div className="truevail-configure-overview-header-image">
            <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
          </div>
          <h1>Welcome {user.first_name},</h1>
          <p>Thank you for joining Truevail! To ensure that we create content that truly represents your brand and preferences, we require some information to personalize your account. You may opt-in or out of our done-for-you services based on your markering needs.</p>
        </>
      ),
      completed: <Message { ...this._getMessage() } />
    }
  }

  _getMessage() {
    return {
      icon: 'hourglass-half',
      title: 't(Configuring Your Account)',
      text: 't(Congratulations on finishing your account setup! Our team is configuring your account and you\'ll be notified as soon as it\'s complete. Please monitor your email for any outreach and updates on your services)',
      buttons: [
        {
          label: 't(Sign out for now)',
          color: 'blue',
          handler: this._handleSignout
        }
      ]
    }
  }

  _handleSignout() {
    this.context.admin.signout()
  }

}

export default Initial
