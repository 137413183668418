import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import PropTypes from 'prop-types'
import React from 'react'

class Results extends React.Component {

  static propTypes = {
    assigned: PropTypes.array,
    records: PropTypes.array,
    onChoose: PropTypes.func
  }

  render() {
    const records = this._getRecords()
    return (
      <div className="truevail-social-batch-campaigns-unassigned-campaigns">
        { records.map((social_campaign, index) => (
          <div className="truevail-social-batch-campaigns-unassigned-campaign" key={`unassigned_${index}`} onClick={ this._handleChoose.bind(this, social_campaign) }>
            <SocialCampaignToken social_campaign={ social_campaign } show_provider={ true }  />
          </div>
        ))}            
      </div>
    )    
  }

  _getRecords() {
    const { assigned, records } = this.props
    return records.filter(a => {
      return assigned.find(b => {
        return a.id === b.id
      }) === undefined
    })
  }

  _getScreenshot(social_campaign) {
    return {
      engine: 'mjson',
      entity: `truevail_social_campaign_variants/${social_campaign.variants[0].code}`,
      width: 100,
      height: 75,
      screenshoted_at: social_campaign.screenshoted_at,
      updated_at: social_campaign.updated_at
    }
  }

  _handleChoose(social_campaign) {
    this.props.onChoose(social_campaign)
  }

}

export default Results
