const triggers = [
  {
    icon: 'check-square',
    manual: true,
    code: 'signup_created',
    title: 't(Signup Created)',
    text: 't(Contact created a new signup through signup page)'
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'signup_configured',
    title: 't(Signup Configured)',
    text: 't(Contact completed configuring team after signup)'
  }
]

export default triggers
