import MJSONPreview from '@admin/components/mjson_preview'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ email_template }) => {

  const details = {
    audits: `truevail_email_templates/${email_template.id}`,
    comments: `truevail_email_templates/${email_template.id}`,
    header: <MJSONPreview entity={ email_template } table="truevail_email_templates" />,
    sections: [
      {
        title: 't(Email Details)',
        items: [
          { label: 't(Title)', content: email_template.title },
          { label: 't(Subject)', content: email_template.subject },
          { label: 't(Preview Text)', content: email_template.preview_text },
          { label: 't(Voice)', content: `${email_template.perspective}, ${email_template.language}` },
          { label: 't(Tags)', content: email_template.tags.length > 0 ? <TagsToken tags={ email_template.tags } /> : null }
        ]
      }
    ]
  }

  if(email_template.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This email template was deleted)' }
  } else if(email_template.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This email template is not published to the library)' }
  } else if(email_template.status === 'translating') {
    details.alert = { color: 'teal', message: 't(This email template is being translated)' }
  } else if(email_template.status === 'published') {
    details.alert = { color: 'green', message: 't(This email template is published to the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  email_template: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email_template={ props.email_template } />,
  tasks: {
    items: [
      {
        label: 't(Edit Email Template)',
        modal: <Edit email_template={ props.email_template } />
      },
      {
        label: 't(Publish to Library)',
        show: props.email_template.status === 'draft',
        confirm: 't(Are you sure you want to publish this template?)',
        request: {
          endpoint: `/api/admin/truevail/admin/email_templates/${props.email_template.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully published email template)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish email template)')
        }
      },
      {
        label: 't(Unpublish from Library)',
        show: props.email_template.status === 'published',
        confirm: 't(Are you sure you want to unpublish this template?)',
        request: {
          endpoint: `/api/admin/truevail/admin/email_templates/${props.email_template.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished email template)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish email template)')
        }
      },
      {
        label: 't(Clone Email Template)',
        modal: <Clone email_template={ props.email_template } />
      },
      {
        label: 't(Delete Email Template)',
        confirm: 't(Are you sure you want to delete this email template? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/truevail/admin/email_templates/${props.email_template.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted email template)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete email template)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
