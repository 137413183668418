
import ModalPanel from '@admin/components/modal_panel'
import Img from '@admin/components/html/img'
import PropTypes from 'prop-types'
import React from 'react'

class Complete extends React.PureComponent {

  static propTypes = {
    completed: PropTypes.any,
    title: PropTypes.string
  }

  render() {
    const { completed } = this.props
    return (
      <ModalPanel { ...this._getPanel() }>
        <div className="truevail-configure-configuring">
          <div className="truevail-configure-configuring-header">
            <div className="truevail-configure-overview-header-image">
              <Img src="https://assets.mahaplatform.com/imagecache/fit=cover&w=1000&h=250/assets/08dab79852a8a1440b5dd7ea8148b179/halaszbastya.jpg.webp" />
            </div>
          </div>
          <div className="truevail-configure-configuring-body">
            { completed }
          </div>
        </div>
      </ModalPanel>
    )
  }

  _getPanel() {
    const { title } = this.props
    return { title }
  }

}

export default Complete
