import MJSONScreenshot from '@admin/components/mjson_screenshot'
import VoiceToken from '@apps/truevail/admin/tokens/voice'
import TagsToken from '@apps/crm/admin/tokens/tags'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_email_campaigns/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at,
  placeholder: entity.status === 'pending'
})

const EmailCampaignToken = ({ email_campaign, show_provider }) => (
  <div className="campaign-token">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email_campaign) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { email_campaign.title }        
      </div>
      { show_provider ?
        <>
          { email_campaign.agency ?
            <div className="token-stats">
              <span>Agency: { email_campaign.agency.title }</span>
            </div> :
            email_campaign.team ?
              <div className="token-stats">
                <span>Team: { email_campaign.team.title }</span>
              </div> :
              <div className="token-stats">
                <span>Provider: Truevail</span>
              </div>    
          }
        </> :
        <>
          <div className="token-stats">
            <VoiceToken perspective={ email_campaign.perspective } language={ email_campaign.language } />
          </div>
          { email_campaign.tags.length > 0 &&
            <div className="token-stats">
              <TagsToken tags={ email_campaign.tags } />
            </div>
          }
        </>
      }
    </div>
  </div>
)

EmailCampaignToken.propTypes = {
  email_campaign: PropTypes.object,
  show_provider: PropTypes.bool
}

export default EmailCampaignToken
