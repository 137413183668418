import SubscriptionProfilesToken from '@apps/truevail/admin/tokens/subscription_profiles'
import Request from '@apps/truevail/admin/components/configuration/request'
import Edit from '@apps/truevail/admin/components/configuration/edit'
import TimeframeToken from '@apps/truevail/admin/tokens/timeframe'
import Details from '@admin/components/details'
import AssetToken from '@admin/tokens/asset'
import Panel from '@admin/components/panel'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

const Configuration = ({ setup }) => {

  const { configuration } = setup

  const details = {
    sections: [
      {
        title: 't(Configuration Details)',
        items: [
          { label: 't(Last Reminded At)', content: setup.last_reminded_at, format: 'datetime' },
          { label: 't(Reminders Sent)', content: setup.reminders_count || 0 },
          { label: 't(Configured At)', content: setup.configured_at, format: 'datetime' }
        ]
      },
      {
        subtitle: 't(Business Information)',
        items: [
          { label: 't(Business Name)', content: configuration.identity_business_name },
          { label: 't(Agency / Host Agency)', content: configuration.identity_host_agency },
          { label: 't(Consortia)', content: configuration.identity_consortia },
          { label: 't(Business Address)', content: configuration.identity_business_address ? configuration.identity_business_address.description : null },
          { label: 't(Business Email)', content: configuration.identity_business_email },
          { label: 't(Business Phone)', content: configuration.identity_business_phone },
          { label: 't(Facebook)', content: configuration.identity_facebook, format: 'link' },
          { label: 't(Instagram)', content: configuration.identity_instagram, format: 'link' },
          { label: 't(LinkedIn)', content: configuration.identity_linkedin, format: 'link' },
          { label: 't(YouTube)', content: configuration.identity_youtube, format: 'link' },
          { label: 't(Pinterest)', content: configuration.identity_pinterest, format: 'link' },
          { label: 't(TikTok)', content: configuration.identity_tiktok, format: 'link' },
          { label: 't(Threads)', content: configuration.identity_threads, format: 'link' },
          { label: 't(Vimeo)', content: configuration.identity_vimeo, format: 'link' },
          { label: 't(X)', content: configuration.identity_x, format: 'link' }
        ]
      },
      {
        subtitle: 't(Logo)',
        items: [
          { label: 't(Logo Strategy)', content: configuration.logo_strategy },
          { label: 't(Logo)', content: configuration.logo_image_id ? (
            <AssetToken { ...configuration.logo_image } preview={ true } />
          ) : null },
          { label: 't(Tagline/Slogan)', content: configuration.logo_tagline_slogan },
          { label: 't(Wording)', content: configuration.logo_wording },
          { label: 't(Meaning)', content: configuration.logo_meaning },
          { label: 't(Brand Description)', content: configuration.logo_brand_description },
          { label: 't(Object/Images)', content: configuration.logo_object_images },
          { label: 't(Attributes/Emotions)', content: configuration.logo_attributes_emotions },
          { label: 't(Competitors)', content: configuration.logo_competitors },
          { label: 't(Liked Logos)', content: configuration.logo_liked_logos },
          { label: 't(Liked Images)', content: configuration.logo_liked_image_ids ? configuration.logo_liked_images.map((image,index) => (
            <AssetToken { ...image } key={`image_${index}`} preview={ true } />
          )) : null },
          { label: 't(Disliked Logos)', content: configuration.logo_disliked_logos },
          { label: 't(Dos)', content: configuration.logo_dos },
          { label: 't(Donts)', content: configuration.logo_donts },
          { label: 't(Colors)', content: configuration.logo_colors },
          { label: 't(Disliked Colors)', content: configuration.logo_colors_dislike },
          { label: 't(Design Styles)', content: configuration.logo_design_styles ? configuration.logo_design_styles.join(', ') : null },
          { label: 't(Font Styles)', content: configuration.logo_font_styles ? configuration.logo_font_styles.join(', ') : null },
          { label: 't(Look and Feel)', content: configuration.logo_look_and_feel ? configuration.logo_look_and_feel.join(', ') : null },
          { label: 't(Notes)', content: configuration.logo_notes }
        ]
      },
      {
        subtitle: 't(Brand)',
        items: [
          { label: 't(Color Strategy)', content: configuration.brand_color_strategy },
          { label: 't(Color 1)', content: configuration.brand_color1 },
          { label: 't(Color 2)', content: configuration.brand_color2 },
          { label: 't(Color 3)', content: configuration.brand_color3 },
          { label: 't(Brand Book / Style Guide)', content: configuration.brand_style_guide_id ? (
            <AssetToken { ...configuration.brand_style_guide } />
          ) : null }
        ]
      },
      {
        subtitle: 't(Personalization)',
        items: [
          { label: 't(Photo Strategy)', content: configuration.personalization_photo_strategy },
          { label: 't(Photo)', content: configuration.personalization_photo_id ? (
            <AssetToken { ...configuration.personalization_photo } preview={ true } />
          ) : null },
          { label: 't(Public Domain)', content: configuration.personalization_public_domain },
          { label: 't(Voice)', content: configuration.personalization_voice },
          { label: 't(Timezone)', content: configuration.personalization_timezone }
        ]
      },
      {
        subtitle: 't(Email Newsletters)',
        items: [
          { label: 't(Email Strategy)', content: configuration.email_strategy },
          { label: 't(Contacts)', content: configuration.email_contacts_id ? (
            <AssetToken { ...configuration.email_contacts } />
          ) : null },
          { label: 't(From Name)', content: configuration.email_from_name },
          { label: 't(From Email)', content: configuration.email_from_email },
          { label: 't(Signature Strategy)', content: configuration.email_signature_strategy },
          { label: 't(Signature)', content: configuration.email_signature_id ? (
            <AssetToken { ...configuration.email_contacts } />
          ) : null },
          { label: 't(Channels)', content: configuration.email_channel_ids ? configuration.email_channels.map((channel, index) => (
            <div className="crm-tag-token" key={`channel_${index}`}>{ channel.title }</div>
          )) : null },
          { label: 't(Scheduling)', content: configuration.email_scheduling }
        ]
      },
      {
        subtitle: 't(Social Media)',
        items: [
          { label: 't(Social Media Strategy)', content: configuration.social_strategy },
          { label: 't(Profiles)', content: configuration.social_profiles ? (
            <SubscriptionProfilesToken subscription_profiles={ configuration.social_profiles } />
          ) : null },
          { label: 't(Channels)', content: configuration.social_channel_ids ? configuration.social_channels.map((channel, index) => (
            <div className="crm-tag-token" key={`channel_${index}`}>{ channel.title }</div>
          )) : null },
          { label: 't(Scheduling)', content: configuration.social_scheduling },
          { label: 't(Timeframe)', content: configuration.social_timeframe ? (
            <TimeframeToken value={ configuration.social_timeframe } />
          ) : null }
        ]
      },
      {
        subtitle: 't(Website)',
        items: [
          { label: 't(Website Strategy)', content: configuration.website_strategy },
          { label: 't(URL)', content: configuration.website_url, format: 'link' },
          { label: 't(Type)', content: configuration.website_type },
          { label: 't(Photo Choices)', content: configuration.website_photo_choices },
          { label: 't(Photos)', content: configuration.website_photo_ids ? configuration.website_photos.map((image, index) => (
            <AssetToken { ...image } key={`image_${index}`} preview={ true } />
          )) : null },
          { label: 't(Contact Form)', content: configuration.website_contact_strategy },
          { label: 't(Calendly URL)', content: configuration.website_calendly_url, format: 'link' },
          { label: 't(Calendly Embed)', content: configuration.website_calendly_embed },
          { label: 't(SEO Title)', content: configuration.website_seo_title },
          { label: 't(SEO Meta Description)', content: configuration.website_seo_meta_description },
          { label: 't(Homepage Experience)', content: configuration.website_homepage_years_of_experience },
          { label: 't(Homepage Personal Photos)', content: configuration.website_homepage_personal_photo_ids ? configuration.website_homepage_personal_photos.map((image, index) => (
            <AssetToken { ...image } key={`image_${index}`} preview={ true } />
          )) : null },
          { label: 't(Homepage About)', content: configuration.website_homepage_about },
          { label: 't(Homepage Travel Styles)', content: configuration.website_homepage_travel_styles },
          { label: 't(Homepage Other Interests)', content: configuration.website_homepage_other_interests },
          { label: 't(Homepage Top Destinations)', content: configuration.website_homepage_top10_destinations },
          { label: 't(Homepage Host Agency Name)', content: configuration.website_homepage_host_agency_name },
          { label: 't(Homepage Host Agency URL)', content: configuration.website_homepage_host_agency_url, format: 'link' },
          { label: 't(Affiliations)', content: configuration.website_homepage_affiliations },
          { label: 't(Testimonials)', content: configuration.website_testimonials ? (
            <List {...{
              items: configuration.website_testimonials,
              format: (testimonial) => (
                <div className="token">
                  { testimonial.client_name }<br />
                  { testimonial.text }<br />
                  { testimonial.date }<br />
                  { testimonial.description }
                </div>
              )
            }} />
          ) : null },
          { label: 't(About Bio)', content: configuration.website_about_bio },
          { label: 't(About Photos)', content: configuration.website_about_photo_ids ? configuration.website_about_photos.map((image, index) => (
            <AssetToken { ...image } key={`image_${index}`} preview={ true } />
          )) : null },
          { label: 't(About Team)', content: configuration.website_about_team ? (
            <List {...{
              items: configuration.website_about_team,
              format: (member) => (
                <div className="token">
                  { member.first_name } { member.last_name }<br />
                  { member.job_title }<br />
                  { member.bio }<br />
                  { member.email }<br />
                  { member.phone }<br />
                  { member.facebook }<br />
                  { member.instagram }<br />
                  { member.linkedin }
                </div>
              )
            }} />
          ) : null },
          { label: 't(Blog Strategy)', content: configuration.website_blog_strategy },
          { label: 't(Blog Posts)', content: configuration.website_blog_posts ? (
            <List {...{
              items: configuration.website_blog_posts,
              format: (post) => (
                <div className="token">
                  { post.title }<br />
                  { post.body }<br />
                </div>
              )
            }} />
          ) : null },
          { label: 't(Blog Show Recent Posts)', content: configuration.website_blog_recent_blog_posts },
          { label: 't(Service Fees)', content: configuration.website_service_fees_id ? (
            <AssetToken { ...configuration.website_service_fees } />
          ) : null },
          { label: 't(Terms and Conditions)', content: configuration.website_terms_conditions_id ? (
            <AssetToken { ...configuration.website_terms_conditions } />
          ) : null },
          { label: 't(Consultation)', content: configuration.website_consultation_id ? (
            <AssetToken { ...configuration.website_consultation } />
          ) : null },
          { label: 't(Consultation Embed)', content: configuration.website_consultation_embed },
          { label: 't(Why Travel Advisor)', content: configuration.website_why_travel_advisor },
          { label: 't(Booking Tools)', content: configuration.website_booking_tools ? (
            <List {...{
              items: configuration.website_booking_tools,
              format: (tool) => (
                <div className="token">
                  { tool.name }<br />
                  { tool.description }<br />
                  { tool.url }
                </div>
              )
            }} />
          ) : null },
          { label: 't(Notes)', content: configuration.website_notes }
        ]
      },
      {
        subtitle: 't(Additional Information)',
        items: [
          { label: 't(Notes)', content: configuration.additional_notes }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Configuration.propTypes = {
  advisor: PropTypes.object,
  channels: PropTypes.array,
  setup: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Configuration)',
  panel: <Configuration setup={ props.setup} />,
  tasks: {
    items: [
      {
        label: 't(Edit Configuration)',
        modal: <Edit advisor={ props.advisor } />
      },
      {
        label: 't(Request Additional Information)',
        modal: <Request advisor={ props.advisor } />
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  setup: `/api/admin/truevail/admin/advisors/${props.advisor.id}/configuration`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
