import Panel from '@admin/components/panel'
import Details from '@admin/components/details'
import PropTypes from 'prop-types'
import Publish from '../publish'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ social_batch }) => {

  const details = {
    audits: `truevail_social_batches/${social_batch.id}`,
    comments: `truevail_social_batches/${social_batch.id}`,
    sections: [
      {
        title: 't(Social Batch Details)',
        items: [
          { label: 't(Title)', content: social_batch.title },
          { label: 't(Voice)', content: `${social_batch.perspective}, ${social_batch.language}` },
        ]
      }
    ]
  }

  if(social_batch.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This social batch was deleted)' }
  } else if(social_batch.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This social batch is not published to the library)' }
  } else if(social_batch.status === 'published') {
    details.alert = { color: 'green', message: 't(This social batch is published to the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  social_batch: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview social_batch={ props.social_batch } />,
  tasks: {
    items: [
      {
        label: 't(Edit Social Batch)',
        modal: <Edit social_batch={ props.social_batch } />
      },
      {
        label: 't(Clone Social Batch)',
        modal: <Clone social_batch={ props.social_batch } />
      },
      {
        label: 't(Publish to Library)',
        show: props.social_batch.status === 'draft',
        confirm: 't(Are you sure you want to publish this campaign? Once it has been published, you cannot change the content of this social batch.)',
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully published social batch)'),
          onFailure: () => context.flash.set('error', 't(Unable to publish social batch)')
        }
      },
      {
        label: 't(Unpublish from Library)',
        show: props.social_batch.status === 'published',
        confirm: 't(Are you sure you want to unpublish this social batch?)',
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished social batch)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social batch)')
        }
      },
      {
        label: 't(Publish Advisor Campaigns)',
        modal: <Publish social_batch={ props.social_batch } />
      },
      {
        label: 't(Delete Social Batch)',
        confirm: 't(Are you sure you want to delete this social batch?)',
        request: {
          endpoint: `/api/admin/truevail/agency/social_batches/${props.social_batch.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted social batch)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete social batch)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
