import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import EmailTemplates from './email_templates'
import SocialBatches from './social_batches'
import EmailArticles from './email_articles'
import SenderDomains from './sender_domains'
import SupportUsers from './support_users'
import EmailModules from './email_modules'
import Page from '@admin/components/page'
import SignupPages from './signup_pages'
import Template from './notices/design'
import Profiles from './profiles'
import Forecast from './forecast'
import Senders from './senders'
import Agencies from './agencies'
import Advisors from './advisors'
import Notices from './notices'
import Signups from './signups'
import Users from './users'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Truevail)',
  manager: {
    path: '/admin/truevail/admin',
    items: [
      {
        label: 't(Agencies)',
        path: '/agencies',
        panel: <Agencies />
      },
      {
        label: 't(Advisors)',
        path: '/advisors',
        panel: <Advisors />
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Articles)',
            path: '/email_articles',
            panel: <EmailArticles />
          },
          {
            label: 't(Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns />
          },
          {
            label: 't(Forecast)',
            path: '/forecast',
            panel: <Forecast />
          },
          {
            label: 't(Modules)',
            path: '/email_modules',
            panel: <EmailModules />
          },
          {
            label: 't(Senders)',
            path: '/senders',
            panel: <Senders />
          },
          {
            label: 't(Sender Domains)',
            path: '/sender_domains',
            panel: <SenderDomains />
          },
          {
            label: 't(Templates)',
            path: '/email_templates',
            panel: <EmailTemplates />
          }
        ]
      },
      {
        label: 't(Social Media)',
        children: [
          {
            label: 't(Batches)',
            path: '/social_batches',
            panel: <SocialBatches />
          },
          {
            label: 't(Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns />
          },
          {
            label: 't(Profiles)',
            path: '/profiles',
            panel: <Profiles />
          }
        ]
      },
      {
        label: 't(Truevail)',
        children: [
          {
            label: 't(Notice Template)',
            path: '/template',
            panel: <Template />
          },
          {
            label: 't(Notices)',
            path: '/notices',
            panel: <Notices  />
          },
          {
            label: 't(Signup Pages)',
            path: '/signup_pages',
            panel: <SignupPages />
          },
          {
            label: 't(Signups)',
            path: '/signups',
            panel: <Signups />
          }
        ]
      },
      {
        label: 't(Support Users)',
        path: '/support_users',
        panel: <SupportUsers />
      },
      {
        label: 't(Users)',
        path: '/users',
        panel: <Users />
      }
    ]
  }
})


export default Page(null, mapPropsToPage)
